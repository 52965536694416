// const API_URL = 'https://futurum-chatbot-service.onrender.com/api/chat-bot/business-solution';
const API_URL = new URL('http://localhost:8081/api/chat-bot/business-solution');

export const sendChatMessage = async (query) => {
  const url = new URL(API_URL);
  url.searchParams.append('query', query);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.text();
    return data;
  } catch (error) {
    console.error('Error fetching bot response:', error);
    throw error; // Re-throw the error so it can be handled in the component
  }
};
