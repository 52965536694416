import React, { useState, useRef, useEffect } from 'react';
import './BusinessSolution.css';
import chatbot from '../../../../assets/images/chatbot.png';
import guest from '../../../../assets/images/guest.png';
import typing from '../../../../assets/images/loading-type.svg';  
import { sendChatMessage } from '../../../../features/auth/chatService';

const BusinessSolution = () => {
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const chatRoomRef = useRef(null);

  const suggestions = ["How can I improve my business?", "Tell me about AI solutions.", "What are the latest market trends?"];

  const typeMessage = (message, callback) => {
    let index = 0;
    const typingSpeed = 50;

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const lastMessage = updatedMessages[updatedMessages.length - 1];

      if (lastMessage && lastMessage.sender === 'bot') {
        lastMessage.text = '';
      }

      return updatedMessages;
    });
    setLoading(false);

    const typeInterval = setInterval(() => {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];

        if (lastMessage && lastMessage.sender === 'bot') {
          if (index < message.length) {
            lastMessage.text += message[index];
            index++;
          } else {
            clearInterval(typeInterval);
            callback();
          }
          return updatedMessages;
        } else {
          return prevMessages;
        }
      });
    }, typingSpeed);
  };

  const sendMessage = async (text = messageText) => {
    if (text.trim()) {
      setMessages([...messages, { text, sender: 'user' }]);
      setMessageText('');
      setLoading(true);
      setShowSuggestions(false);

      try {
        const data = await sendChatMessage(text); // Use the service

        setMessages((prevMessages) => [
          ...prevMessages,
          { text: '', sender: 'bot' } 
        ]);

        typeMessage(data || 'Sorry, I could not understand.', () => {
          setLoading(false);
        });
      } catch (error) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: 'Error fetching response. Please try again.', sender: 'bot' }
        ]);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (chatRoomRef.current) {
      chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="b-container">
      <span className="ai-nam">Welcome to Futurbot Business</span>
      <h5>Your personalized AI Business assistant - powered by Futurum AI</h5>

      <div className="">
        <div className="ai-chat-room" ref={chatRoomRef}>
          <div className="message-slider">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`hhh ${message.sender === 'bot' ? 'bot-message' : 'user-message'}`}
              >
                <div className="avatar-wrapper avatar-small">
                  <img
                    width={'30px'}
                    src={message.sender === 'bot' ? chatbot : null}
                    alt=""
                  />
                </div>
                <div className={`bubble ${message.sender === 'bot' ? 'bubble-dark' : 'bubble-light'}`}>
                  {message.text.split('\n').map((line, i) => (
                    <p key={i} className="message-line">{line}</p>
                  ))}
                </div>
              </div>
            ))}
            {loading && (
              <div className="hhh bot-message">
                <div className="avatar-wrapper avatar-small">
                  <img width={'30px'} src={chatbot} alt="avatar" />
                </div>
                <div className="bubble bubble-light typing-indicator">
                  <img src={typing} width={30} alt='loading' />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showSuggestions && (
        <div className="suggestions">
          {suggestions.map((suggestion, index) => (
            <button
              key={index}
              className="suggestion-button"
              onClick={() => sendMessage(suggestion)}
            >
              {suggestion}
            </button>
          ))}
        </div>
      )}
      <div className="type-area">
        <div className="input-container">
          <input
            className="chat-input"
            type="text"
            id="inputText"
            placeholder="Type messages here..."
            value={messageText}
            onChange={(e) => {
              setMessageText(e.target.value);
              setShowSuggestions(false);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage();
              }
            }}
          />
          <button className="search-header__button" onClick={() => sendMessage()}>
            <svg
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="search-header__icon"
            >
              <path
                opacity="0.15"
                d="M20 4L3 11L10 14L13 21L20 4Z"
                fill="#FFFFFF"
              />
              <path
                d="M20 4L3 11L10 14M20 4L13 21L10 14M20 4L10 14"
                stroke="#FFFFFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessSolution;
