
import { Route, Switch } from "react-router-dom";
import DashboardNav from "../../Components/Dashboard/DashboardNav/DashboardNav";
import './UserDashboard.css';
import Menu from "../../Components/Dashboard/Menu/Menu";
import HomeDashboard from "../../Components/Dashboard/HomeDashboard/HomeDashboard";
import Settings from "../../Components/Dashboard/Settings/Settings";
import BusinessSolution from "../../Components/Dashboard/Tools/BusinessSolution/BusinessSolution";
import MyProfile from "../../Components/Dashboard/MyProfile/MyProfile";

const UserDashboard = () => {
  return (
    <>
      <div className="">
        <DashboardNav />
        <div className="container">
          <div className="menuu">
            <Menu />
          </div>
          <div>
            {/* Nested Routes */}
            <Switch>
              <Route exact path="/dashboard" component={HomeDashboard}/>
              <Route exact path="/dashboard/settings" component={Settings}/>
              <Route exact path="/dashboard/business-solution" component={BusinessSolution}/>
              <Route exact path="/dashboard/update-profile" component={MyProfile}/>

            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
