import './Menu.css';
import dashboard from '../../../assets/icons/dashboard.png';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Menu = () => {
    const history = useHistory();
    const location = useLocation();

    const handleHome = () => {
        history.push('/dashboard');
    };

    const handleSettings = () => {
        history.push('/dashboard/settings');
    };

    return (
        <div className="men">
            <div onClick={handleHome} className={`${location.pathname === '/dashboard' ? 'cont-menu' : 'cont-menu-off'}`}>
                <div className='menu-but'>
                    <img src={dashboard} alt="Dashboard Icon" width={20} />
                    <p className="m1" > Home</p>
                </div>
            </div>
            {/* <div onClick={handleSettings} className={`${location.pathname === '/dashboard/settings' ? 'cont-menu' : 'cont-menu-off'}`}>
                <div className='menu-but'>
                    <img src={dashboard} alt="Settings Icon" width={20} />
                    <p className="m1" > Settings</p>
                </div>
            </div> */}
        </div>
    );
}

export default Menu;
