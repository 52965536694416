// Register.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { login, register, reset } from '../../../features/auth/authSlice';
import futurum from './../../../assets/images/futurum.png';
import "./Register.css";
import Login from '../login/Login';

const Register = () => {
    const [formData, setFormData] = useState({
        userName: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });

    const { userName, firstName, lastName, email, password } = formData;

    const history = useHistory();
    const dispatch = useDispatch();
    const { user, isLoading, isError, isSuccess, message, validationErrors } = useSelector((state) => state.user);

    useEffect(() => {
        if (isError) {
            console.log(message);
        }

        if (isSuccess || user) {
            history.push('/dashboard');
        }

        // dispatch(reset());
    }, [user, isError, isSuccess, message, history, dispatch]);

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const onSubmitRegister = (e) => {
        e.preventDefault();

        const userData = {
            userName,
            firstName,
            lastName,
            email,
            password,
        };

        dispatch(register(userData));
    };

    const onSubmitLogin = (e) => {
        e.preventDefault();

        const userData = {
            userName,
            password,
        };
        dispatch(login(userData));
    };

    const handleHome = () => {
        history.push('/');
    };

    return (
        <>
            <LoginAnim handleHome={handleHome} futurum={futurum} />
            <div className="wrapper">
                <label className="switch">
                    <input type="checkbox" className="toggle" />
                    <span className="slider"></span>
                    <span className="card-side"></span>
                    <div className="flip-card__inner">
                        {/* Use the LoginForm component */}
                        <Login
                            userName={userName}
                            password={password}
                            onChange={onChange}
                            onSubmitLogin={onSubmitLogin}
                            isError={isError}
                            isLoading={isLoading}
                        />
                        <div className="flip-card__back">
                            <div className="title">Welcome, <span> sign up to continue</span>
                            </div>

                            <form className="flip-card__form" onSubmit={onSubmitRegister}>
                                <input className="flip-card__input" name="userName" placeholder="Username" type="text" value={userName} onChange={onChange} />
                                {message.errorDetails?.userName && <p id='overlay' className="error">*{message.errorDetails.userName}</p>}

                                <input className="flip-card__input" name="firstName" placeholder="First Name" type="text" value={firstName} onChange={onChange} />
                                {message.errorDetails?.firstName && <p id='overlay' className="error">*{message.errorDetails.firstName}</p>}

                                <input className="flip-card__input" name="lastName" placeholder="Last Name" type="text" value={lastName} onChange={onChange} />
                                {message.errorDetails?.lastName && <p id='overlay' className="error">*{message.errorDetails.lastName}</p>}

                                <input className="flip-card__input" name="email" placeholder="Email" type="email" value={email} onChange={onChange} />
                                {message.errorDetails?.email && <p id='overlay' className="error">*{message.errorDetails.email}</p>}

                                <input className="flip-card__input" name="password" placeholder="Password" type="password" value={password} onChange={onChange} />
                                {message.errorDetails?.password && <p id='overlay' className="error">*{message.errorDetails.password}</p>}

                                <div className="login-with">
                                    {/* Social login buttons */}
                                </div>
                                <button className="flip-card__btn">
                                    {isLoading ? 'loading...' : 'Confirm!'}
                                </button>
                            </form>
                        </div>
                    </div>
                </label>
            </div>
        </>
    );

    function LoginAnim({ handleHome, futurum }) {
        return (
            <div className="LoginAnim">
                <div className="log" onClick={handleHome}>
                    {/* <img src={futurum} alt="futurum" /> */}
                </div>
                <h1 className="hero-heading">
                    <div className="glitch-wrapper">
                        <div className="glitch" data-text="WELCOME 2 DA FUTURE">WELCOME 2 DA FUTURE</div>
                    </div>
                </h1>
                <div id="hey"><div id="layer-up"></div></div>
                <div>
                    <div id="layer-0">
                        <div id="layer-1">
                            <div id="layer-2">
                                <div id="lines">
                                    <div id="layer-corner"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="mtnZZZ"></div>
            </div>
        );
    }
};

export default Register;
