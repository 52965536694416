import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './app/store';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

// PayPal client ID and other options
const initialOptions = {
  clientId: "Aaj7mRXTRUkJv0BuPcMUcc3LqzD_GPNW1Ihds9lQA4JhXrSiLjL9vCpWK9QWTDnzl-NJCco-d25NqpJq", // Replace with your PayPal client ID
  currency: "USD" // Set your preferred currency
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <PayPalScriptProvider options={initialOptions}>
        <App />
      </PayPalScriptProvider>
      <script>
        AOS.init(); // Initialize AOS (Animate On Scroll)
      </script>
    </React.StrictMode>
  </Provider>
);