import './HomeDashboard.css';
import kpi from '../../../assets/images/Frame 22.png';
import ai from '../../../assets/icons/ai model.png';
import more from '../../../assets/icons/More.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const HomeDashboard = () => {
    const history = useHistory();

    const goToBusiness = () => {
        history.push('/dashboard/business-solution');
    };
    const aiModels = [
        {
            title: 'Predictive Analytics',
        },
        {
            title: 'Natural Language Processing',
        },
        {
            title: 'Machine Learning',
        },
        {
            title: 'Decision Management',
        },
        {
            title: 'Robotic Process Automation',
        },
        {
            title: 'Virtual Agents',
        },
        {
            title: 'Content Creation',
        },
        {
            title: 'Marketing Automation',
        },
        {
            title: 'Knowledge Work Automation',
        },
        {
            title: 'Streamlining Operations',
        },
        {
            title: 'Optimizing Supply Chain',
        },
    ];

    return (
        <div className='d'>
            <div className='d1'>
                <h3 className='dash-title'>Home</h3>
                <div className="p1">
                    <div className='grad-cont'>
                        <div className='grad-bg'>
                            <img src={kpi} width={'100%'} alt="KPIs" />
                            <h5 className='dash-title2'>KPIS</h5>
                        </div>
                    </div>
                </div>
                <h3 className='dash-title'>Featured Tools</h3>
                <div className='p2'>

                    <div className='ai' onClick={goToBusiness}>
                        <div className='grad-bg'>
                            <h5 className='ai-name'>Business Solution</h5>
                            <p className='ai-details'>Grow your businesses needs across all sectors</p>
                        </div>
                    </div>
                    {/* <div className='ai'>
                        <div className='grad-bg'>
                            <h5 className='dash-title2'>Customers</h5>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* <div className='d2'>
                <div className='ai-part'>
                    <div className='ai-models'>
                        <div className='ai-model'>
                            <img src={ai} width={26} height={26} alt="AI Models" />
                            <h5 className='dash-title3'>AI Models</h5>
                        </div>
                        <ul className='ai-list'>
                            {aiModels.map((model, index) => (
                                <li key={index} className='ai-item'>
                                    <img src={ai} width={26} height={26} alt="AI Models" />
                                    <h6 className='ai-title'>{model.title}</h6>
                                    <img src={more} width={26} height={26} alt="AI Models" />

                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default HomeDashboard;
