import React, { useState, useEffect } from 'react';
import futurum from "./../../assets/images/futurum.png";
import './Navbar.css';
import Button from '../../shared/button/Button';
import SearchInput from "../../shared/search/Search";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const history = useHistory();
  const [buttonText, setButtonText] = useState("Get Started");

  const handleResize = () => {
    if (window.innerWidth <= 580) {
      setButtonText("Get Started");
    } else {
      setButtonText("Get Started");
    }
  };

  useEffect(() => {
    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleHome = () => {
    history.push('/');
  };
  const handleLogin = () => {
    history.push('/login');
  };
  const handleRegister = () => {
    history.push('/register');
  };
  return (
    <header className="header">
      <div data-aos="fade-down" className="contain">
        <div className="logo" onClick={handleHome}>
          <img src={futurum} alt="futurum" />
        </div>
        <div className="searchInput">
          <SearchInput />
        </div>
        <div className="right">
          <Button className="signup-button" onClick={handleRegister}>{buttonText}</Button>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
