// src/features/auth/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { registerUser, loginUser } from './authService';
const initialState = {
    user: JSON.parse(localStorage.getItem('user')) || null,    
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: '',
    validationErrors: {}, 
};

// Register user
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
    try {
        const response = await registerUser(user);
        localStorage.setItem('user', JSON.stringify(response.user));
        localStorage.setItem('token', response.token);
        return response
    } catch (error) {
        let message = error.message || 'Unknown error occurred';
        let errorDetails = {};

        if (error.response && error.response.data && error.response.data.error) {
            const errorData = error.response.data.error;
            message = errorData.message || message;

            if (errorData.details && Array.isArray(errorData.details)) {
                errorData.details.forEach(detail => {
                    errorDetails[detail.field] = detail.message;
                });
            }
        }

        return thunkAPI.rejectWithValue({ message, errorDetails });
    }
});
// Login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
    try {
        const response = await loginUser(user);
        localStorage.setItem('user', JSON.stringify(response.user));
        localStorage.setItem('token', response.token);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            localStorage.removeItem('user');
            localStorage.removeItem('token');
        },
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.validationErrors = {}; 
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
                state.validationErrors = {}; 
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
                if (action.payload.status === 400 && action.payload.errors) {
                    state.validationErrors = action.payload.details;  // Store validation errors
                }
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
            });
    },
});
export const { logout, reset } = authSlice.actions;
export default authSlice.reducer;
