import robothand from "./../../assets/images/robot arm.png"
import humanhand from "./../../assets/images/human arm.png"
import power from "./../../assets/images/power.png"
import AOS from "aos";
import "aos/dist/aos.css";
import './Home.css';
import Footer from "../../Components/Footer/Footer";
import BgAnim from "../../shared/bg-anim/BgAnim";
import ChatBox from "../../Components/chatbox/Chatbox";
import { useEffect } from "react";
import obj1 from "../../assets/images/obj1.png"
import aibuilder from "../../assets/images/aibuilder.png"
import aisolution from "../../assets/images/aisolution.png"
import nft from "../../assets/images/animnft.json"
import crypto from "../../assets/images/animncrypto.json"
import Lottie from 'lottie-react';
import Button from "../../shared/button/Button";
const Home = () => {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <div className="home">
      <BgAnim />
      <div className="news">
        <BgAnim />
        <h6 className="n">New: See the new updates</h6>
        <svg className="nn" width="19" height="26" viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Group 67">
            <path id="Vector" d="M9.5 0C8.60252 5.25001 7.87497 6.03349 3 7C7.87501 7.96651 8.60252 8.74999 9.5 14C10.3975 8.74999 11.125 7.96651 16 7C11.125 6.03349 10.3974 5.25001 9.5 0Z" fill="#9F9F9F" />
            <path id="Vector_2" d="M14.5 15C13.8787 19.125 13.375 19.7407 10 20.5C13.375 21.2594 13.8787 21.8751 14.5 26C15.1213 21.8751 15.6251 21.2593 19 20.5C15.625 19.7407 15.1213 19.125 14.5 15Z" fill="#9F9F9F" />
            <path id="Vector_3" d="M4.50002 14C3.87875 17 3.37497 17.4478 0 18C3.37497 18.5523 3.87875 19 4.50002 22C5.1213 19 5.62507 18.5522 9 18C5.62498 17.4478 5.12134 17 4.50002 14Z" fill="#9F9F9F" />
          </g>
        </svg>
      </div>
      <h1 className="titlehome">The future is in your hands</h1>
      {/* <h2 className="titlehome">Empowering the Future with Intelligent Innovation</h2> */}


      <div className="arms">
        <div data-aos="fade-right" className="robothand">
          <img src={robothand} alt="fffffffff" />
        </div>
        <div className="mid">
          <div >
            <svg className="powerr" xmlns="http://www.w3.org/2000/svg" width="93" height="106" viewBox="0 0 93 106" fill="none">
              <path d="M46.5 105.394C72.1408 105.394 93 84.2022 93 58.1521C93 40.1524 82.7037 23.4763 66.7661 15.6731C66.0041 15.2946 65.1756 15.0741 64.3288 15.0247C63.482 14.9753 62.6341 15.0979 61.8345 15.3853C60.6313 15.8158 59.5803 16.5984 58.8139 17.6344C58.0476 18.6705 57.6001 19.9137 57.5277 21.2077C57.4554 22.5016 57.7615 23.7883 58.4075 24.9059C59.0535 26.0234 60.0106 26.9218 61.1581 27.4879C72.6573 33.1211 80.0846 45.1593 80.0846 58.1521C80.0846 76.9678 65.02 92.2762 46.4967 92.2762C27.98 92.2762 12.912 76.9678 12.912 58.1521C12.912 45.1559 20.3427 33.1211 31.8453 27.4879C33.3864 26.7308 34.5691 25.3836 35.1338 23.7417C35.6985 22.0999 35.5991 20.2975 34.8575 18.73C34.4902 17.9507 33.9741 17.2534 33.3391 16.6786C32.7042 16.1038 31.963 15.663 31.1588 15.382C30.361 15.0969 29.5153 14.9759 28.6709 15.0259C27.8266 15.0758 27.0005 15.2958 26.2406 15.6731C10.2996 23.4763 0 40.149 0 58.1521C0 84.2022 20.8592 105.394 46.5 105.394Z" fill="url(#paint0_linear_244_98)" />
              <path d="M45.8469 38.5915C47.5832 38.5889 49.2475 37.8989 50.475 36.673C51.7024 35.447 52.3927 33.7852 52.3945 32.0519V7.14188C52.3918 5.40918 51.7011 3.74821 50.4738 2.52301C49.2464 1.2978 47.5826 0.608299 45.8469 0.605621C44.11 0.607408 42.4448 1.29698 41.2166 2.52301C39.9885 3.74904 39.2977 5.41139 39.2959 7.14526V32.0519C39.2977 33.7858 39.9885 35.4481 41.2166 36.6741C42.4448 37.9002 44.11 38.5897 45.8469 38.5915Z" fill="url(#paint1_linear_244_98)" />
              <defs>
                <linearGradient id="paint0_linear_244_98" x1="62.1742" y1="3.97927" x2="46.3241" y2="105.367" gradientUnits="userSpaceOnUse">
                  <stop />
                  <stop offset="0.5" stopColor="#000080" />
                  <stop offset="1" stopColor="#6B1561" />
                </linearGradient>
                <linearGradient id="paint1_linear_244_98" x1="18.4877" y1="87.708" x2="70.9388" y2="55.8867" gradientUnits="userSpaceOnUse">
                  <stop />
                  <stop offset="0.5" stopColor="#000080" />
                  <stop offset="1" stopColor="#6B1561" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <img className="power" src={obj1} alt="" />
        </div>
        <div className="chathome">
          {/* <HomeChat /> */}
          <ChatBox />
        </div>
        <div data-aos="fade-left" className="humanhand">
          <img src={humanhand} alt="" />
        </div>
      </div>
      <div className="aboutus">
        <div data-aos="fade-left" className="aisolution">
          <div className="solution">
            <h3>AI Builder</h3><br />
            <h4>Empower Your Business with AI Build Smarter Solutions Effortlessly</h4>
            <div className="letsbut">
              <Button className="signup-button" >{'Let`s go →'}</Button>
            </div>
          </div>
          <img className="aisol" src={aibuilder} alt="" />
        </div>
        <div data-aos="fade-right" className="aibuilder">
          <div className="solution">
            <h3>AI Solution</h3><br />
            <h4>Unlock the Power of Automation: Intelligent Solutions for Your Business</h4>
            <div className="letsbut">
              <Button className="signup-button" >{'Let`s go →'}</Button>
            </div>
          </div>
          <img className="aisol" src={aisolution} alt="" />
        </div>
        <div data-aos="fade-left" className="crypto">
          <div className="solution">
            <h3>Crypto Exchange</h3><br />
            <h4>Experience the Future of Finance with Our Crypto Exchange</h4>
            <div className="letsbut">
              <Button className="signup-button" >{'Let`s go →'}</Button>
            </div>
          </div>
          <Lottie animationData={crypto} />
        </div>
        <div data-aos="fade-right" className="nft">
          <div className="solution">
            <h3>NFT Exchange</h3><br />
            <h4>Discover, Buy, and Sell Unique Digital Assets on Our NFT Exchange</h4>
            <div className="letsbut">
              <Button className="signup-button" >{'Let`s go →'}</Button>
            </div>
          </div>
          <div>
            <Lottie animationData={nft} />
          </div>
        </div>
      </div>
      <Footer />

    </div>
  );
};

export default Home;
